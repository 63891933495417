<template>
  <div class="class_box">
    <div class="container">
      <div class="_Tab">
        <span
          v-for="(item, index) in option"
          :key="index"
          :class="{ activeColor: item.value == selectValue }"
          @click="changeTable(item.value)"
          >{{ item.label }}</span
        >
      </div>
      <div class="bottom_box">
        <div class="bottom_box_right">
          <div class="mid">
            <span>任务状态：</span>
            <!-- @change="changeDone" -->
            <el-select
              style="width: 7%; margin-right: 10px"
              v-model="optionChoosevalue"
            >
              <el-option
                style="width: 130px"
                v-for="item in optionChoose"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <span>完成时间：</span>
            <!-- @change="changeData" -->
            <el-date-picker
              style="width: 7%; margin-right: 10px"
              v-model="date1"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>

            <span>发布日期：</span>
            <!-- @change="changeData2" -->
            <el-select
              v-model="dateValue"
              style="width: 7%; margin-right: 10px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dateOption"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <!-- <el-select
              v-model="selectValue"
              style="width: 7%; margin-right: 10px"
              placeholder="请选择"
              @change="changeTable"
            >
              <el-option
                v-for="item in option"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <span>类型：</span>
            <!-- @change="changeTable2" -->
            <el-select
              v-model="typeValue"
              style="width: 7%; margin-right: 10px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in typeValuOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              icon="el-icon-search"
              style="margin-left: 10px"
              @click="getDate()"
            ></el-button>
            <div style="margin-top: 3px; margin-left: 10px" v-if="indexE != 0">
              <img
                style="cursor: pointer"
                @click="deltype"
                src="../../assets/images/Examine_img/del.png"
                alt=""
              />
            </div>

            <div
              class="assign"
              v-show="selectValue == 0 && isLean"
              @click="addEvent(1)"
            >
              <img src="../../assets/images/study.png" alt />
              <span>指派学习</span>
            </div>
            <div
              class="assign"
              v-show="selectValue == 0 && isLean"
              @click="addEvent(0)"
            >
              <img src="../../assets/images/exam.png" alt />
              <span>指派业务测评</span>
            </div>

            <div
              class="assign"
              v-show="selectValue == 0 && isSpecial"
              @click="addEvent(3)"
              style="width: 110px"
            >
              <img src="../../assets/images/study.png" alt />
              <span>指派专题学习</span>
            </div>
            <div
              class="assign"
              v-show="selectValue == 0 && isSpecial"
              @click="addEvent(2)"
              style="width: 110px"
            >
              <img src="../../assets/images/exam.png" alt />
              <span>指派专题业务测评</span>
            </div>
            <!-- <el-button
              v-show="isLean"
              type="primary"
              @click="addEvent(0)"
              style="background: #5a8bff"
              >指派业务测评<i class="el-icon-circle-plus-outline"></i
            ></el-button>
            <el-button
              v-show="isLean"
              type="primary"
              @click="addEvent(1)"
              style="background: #5a8bff"
              >新增指派<i class="el-icon-circle-plus-outline"></i
            ></el-button>
            <el-button
              v-show="isSpecial"
              type="primary"
              @click="addEvent(2)"
              style="background: #5a8bff"
              >指派专题业务测评<i class="el-icon-circle-plus-outline"></i
            ></el-button>
            <el-button
              v-show="isSpecial"
              type="primary"
              @click="addEvent(3)"
              style="background: #5a8bff"
              >指派专题学习<i class="el-icon-circle-plus-outline"></i
            ></el-button> -->
          </div>
          <el-table
            :data="tableData.slice(start, end)"
            style="width: 100%"
            highlight-current-row
            @row-click="handleRow"
            :row-class-name="tableRowClassName"
            :row-style="selectedstyle"
            height="calc(100% - 78px)"
            :header-cell-style="{
              background: '#EDF0F7',
              color: '#000000',
            }"
          >
            <el-table-column prop="compName" width="250" label="公司">
            </el-table-column>
            <el-table-column prop="deptName" width="150" label="部门">
            </el-table-column>
            <el-table-column prop="postName" width="150" label="岗位">
            </el-table-column>
            <el-table-column
              prop="studyUserName"
              label="考核人员"
              width="130"
              v-if="selectValue"
            >
            </el-table-column>
            <el-table-column prop="isTest" label="任务类型" width="180">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.isTest === 1" type="danger">业务测评</span>
                  <span v-if="scope.row.isTest === 0" type="danger">学习</span>
                  <span v-if="scope.row.isTest === 2" type="danger"
                    >专题学习</span
                  >
                  <span v-if="scope.row.isTest === 3" type="danger"
                    >专题业务测评</span
                  >
                  <span v-if="scope.row.isTest === 4" type="danger"
                    >审核业务测评</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="assignUserName" label="发布人" width="130">
            </el-table-column>
            <el-table-column prop="endTime" label="任务时间" width="130">
              <template slot-scope="scope">
                <span>{{ scope.row.endTime | time }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="任务状态">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.status === 0" type="danger"
                    >未完成</span
                  >
                  <span
                    v-if="
                      scope.row.status === 2 &&
                      (scope.row.isTest == 0 || scope.row.isTest == 2)
                    "
                    type="success"
                    >已完成</span
                  >
                  <span
                    v-if="
                      scope.row.status === 2 &&
                      (scope.row.isTest == 1 || scope.row.isTest == 3)
                    "
                    type="danger"
                    >{{ scope.row.score }}分</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="selectValue == 0 ? '操作' : ''" width="210">
              <template slot-scope="scope">
                <div v-if="!selectValue">
                  <el-button
                    @click="toTest(scope.row)"
                    :disabled="scope.row.status == 2"
                    v-if="
                      scope.row.isTest === 1 ||
                      scope.row.isTest === 3 ||
                      scope.row.isTest === 4
                    "
                    type="danger"
                    >去业务测评</el-button
                  >
                  <el-button
                    v-else
                    type="success"
                    :disabled="scope.row.status == 2"
                    @click="toLearn(scope.row)"
                    >去学习</el-button
                  >
                  <el-button
                    type="primary"
                    :disabled="scope.row.isTest == 4"
                    @click="addEvent(4, scope.row)"
                    style="background: #5a8bff"
                    >转派</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增任务指派"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="任务类型：">
          <span v-show="type === 0">文件学习-指派业务测评</span>
          <span v-show="type === 1">文件学习-指派学习</span>
          <span v-show="type === 2">专题学习-指派业务测评</span>
          <span v-show="type === 3">专题学习-指派学习</span>
        </el-form-item>
        <el-form-item label="所属公司：" v-show="type !== 3 && type !== 2">
          <el-select
            style="width: 95%"
            v-model="Company1"
            placeholder=""
            @change="togetArea"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.companyName"
              :value="{ value: item.id, label: item.companyName }"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属部门：" v-show="type !== 3 && type !== 2">
          <el-select
            v-model="deptmentName1"
            placeholder=""
            @change="togetPost"
            style="width: 95%"
          >
            <el-option
              v-for="item in option2"
              :key="item.id"
              :label="item.deptmentName"
              :value="{ value: item.id, label: item.deptmentName }"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属岗位：" v-show="type !== 3 && type !== 2">
          <el-select v-model="Area1.value" placeholder="" style="width: 95%">
            <el-option
              v-for="item in option3"
              :key="item.value"
              :label="item.postName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专题列表：" v-show="type == 3 || type == 2">
          <el-select
            v-model="theme"
            @change="changeList"
            placeholder=""
            style="width: 95%"
          >
            <el-option
              v-for="item in option5"
              :key="item.theme"
              :label="item.theme"
              :value="item.theme"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考核人员">
          <!-- <el-select
            v-if="type == 0"
            v-model="userId"
            placeholder=""
            @change="sss"
            collapse-tags
            filterable
            multiple
            style="width: 95%;"
          >
            <el-option
              v-for="item in option4"
              :key="item.value"
              :label="item.userName"
              :value="{ value: item.id, label: item.userName }"
            >
            </el-option>
          </el-select> -->
          <el-input
            style="width: 95%"
            placeholder="请输入内容"
            v-model="numPerson"
            v-show="type == 1 || type == 0 || type == 3 || type == 2"
            @focus="More"
          >
            <template slot="append"><span @click="More">更多</span></template>
          </el-input>
          <!-- <el-select
            class="typePerson"
            v-show="type == 3 || type == 2"
            v-model="userId"
            placeholder=""
            @change="sss"
            filterable
            collapse-tags
            multiple
            style="width: 95%"
          >
            <el-option
              v-for="item in option6"
              :key="item.value"
              :label="item.userName"
              :value="{ value: item.id, label: item.userName }"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="任务完成时间">
          <el-date-picker
            style="width: 95%"
            v-model="date"
            type="date"
            @change="changeData"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addAssignTest">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="转派任务"
      :visible.sync="dialogVisible1"
      width="23%"
      :before-close="handleClose"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="转派人员：">
          <el-select
            v-model="userId"
            placeholder=""
            @change="sss"
            filterable
            multiple
            style="width: 95%"
          >
            <el-option
              v-for="item in option6"
              :key="item.value"
              :label="item.userName"
              :value="{ value: item.id, label: item.userName }"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务完成时间">
          <el-date-picker
            style="width: 95%"
            v-model="date"
            type="date"
            @change="changeData"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeAssignTest">确 定</el-button>
        <el-button @click="dialogVisible1 = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="筛选区" width="53%" :visible.sync="dialogVisibleMore">
      <div class="title">
        <div style="margin-bottom: 10px"></div>
        <span style="margin-left: 21px">筛选区</span>
      </div>
      <div class="add_box_top">
        <el-select
          style="width: 136px; height: 40px; margin-right: 4px"
          v-model="Company11"
          placeholder="请选择公司"
          @change="togetArea"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="{ value: item.id, label: item.companyName }"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="deptmentName11"
          placeholder="请选择部门"
          @change="togetPost2"
          style="width: 136px; height: 40px; margin-right: 4px"
        >
          <el-option
            v-for="item in option22"
            :key="item.id"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="SearchUser"
          placeholder="搜索人员"
          filterable
          style="width: 136px; height: 40px; margin-right: 4px"
        >
          <el-option
            v-for="item in option4"
            :key="item.value"
            :label="item.userName"
            :value="item.userName"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="Search"
          icon="el-icon-search"
          class="Search"
          style="
            width: 43px;
            background: #edf0f7;
            border: 1px solid #dbdbdb;
            border-radius: 4px;
            margin-right: 59px;
          "
        ></el-button>
        <el-button
          type="primary"
          @click="sureAdd"
          style="background: #5a8bff; width: 117px; border-radius: 2px"
          >添加到选中区 <i class="el-icon-arrow-right el-icon--right"></i
        ></el-button>
        <el-button
          type="primary"
          @click="showChoose"
          style="background: #5a8bff; width: 90px; border-radius: 2px"
          >查看选中区</el-button
        >
      </div>
      <el-table
        :data="option4"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        height="400px"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          property="companyName"
          label="公司"
          width="120"
        ></el-table-column>
        <el-table-column
          property="deptmentName"
          label="部门"
          width="120"
        ></el-table-column>
        <el-table-column
          property="postName"
          label="岗位"
          width="120"
        ></el-table-column>
        <el-table-column property="userName" label="人员"></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="选中区" width="45%" :visible.sync="dialogVisiblechoose">
      <div class="title">
        <div style="margin-bottom: 10px"></div>
        <span style="margin-left: 21px">选中区</span>
      </div>
      <div class="add_box_top">
        <el-select
          v-model="SearchUser"
          placeholder="搜索人员"
          filterable
          style="width: 136px; height: 40px; margin-right: 4px"
        >
          <el-option
            v-for="item in Sruetabel"
            :key="item.value"
            :label="item.userName"
            :value="item.userName"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="searchDelP"
          class="Search"
          style="
            width: 43px;
            background: #edf0f7;
            border: 1px solid #dbdbdb;
            border-radius: 4px;
            margin-right: 343px;
          "
        ></el-button>

        <img
          style="cursor: pointer; margin-right: 4px"
          @click="delChooseItem"
          src="../../assets/images/Examine_img/del.png"
          alt=""
        />

        <el-button
          @click="toSure"
          type="primary"
          style="background: #5a8bff; width: 77px; border-radius: 2px"
          >确定
        </el-button>
      </div>
      <el-table
        :data="Sruetabel"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        ref="multipleTable2"
        @selection-change="handleSelectionChange1"
        height="400px"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          property="companyName"
          label="公司"
          width="120"
        ></el-table-column>
        <el-table-column
          property="deptmentName"
          label="部门"
          width="120"
        ></el-table-column>
        <el-table-column
          property="postName"
          label="岗位"
          width="120"
        ></el-table-column>
        <el-table-column property="userName" label="人员"></el-table-column>
      </el-table>
    </el-dialog>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { deleteMethod, get, post } from "../../api/http";
export default {
  data() {
    return {
      curIndex: 0,
      input1: "",
      gridData: [],
      dialogVisible: false,
      dialogVisible1: false,
      optionChoosevalue: null,
      dialogVisibleMore: false,
      deptmentName1: "",
      dialogVisiblechoose: false,
      Sruetabel: [],
      date2: "",
      SearchUser: "",
      Name: "",
      dateValue: Number(new Date().getFullYear()),
      dateOption: [
        Number(new Date().getFullYear()) - 2,
        Number(new Date().getFullYear()) - 1,
        Number(new Date().getFullYear()),
        Number(new Date().getFullYear()) + 1,
        Number(new Date().getFullYear()) + 2,
      ],
      addtabel: [],
      typeValue: "",
      options: this.$store.state.companyList,
      Company1: "",
      form: {},
      selectValue: 1,
      typeValuOption: [
        { label: "业务测评/专题业务测评", value: 1 },
        { label: "学习/专题学习", value: 0 },
      ],
      option: [
        { label: "我的发布", value: 1 },
        { label: "我的任务", value: 0 },
      ],
      option2: [],
      option3: [],
      num: "",
      numPerson: "",
      Area1: {},
      userName: [],
      option4: [],
      userId: [],
      curE: null,
      type: null,
      endTime: "",
      tableData: [],
      postName: "",
      myCreateNum: "",
      aboutMy: "",
      start: 0,
      end: 10,
      date1: "",
      indexE: 1,
      option6: [],
      theme: [],
      date: "",
      isSpecial: 1,
      isLean: 1,
      themeList: [],
      AssId: "",
      allshow: true,
      changeWork: "",
      optionChoose: [
        { name: "未完成", id: 0 },
        { name: "已完成", id: 1 },
      ],
      currentPage4: 1,
      curSize: 10,
      option5: [],
      personLoading: false,
      Company11: {},
      deptmentName11: "",
      option22: [],
      tableRowIndex: null,
    };
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return e.split("T")[0];
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.path == "/assignStudy" && from.path == "/file-learn") {
        vm.isSpecial = 0;
        sessionStorage.setItem("isSpecial", 0);
        sessionStorage.setItem("isLean", 1);
        vm.isLean = 1;
      } else if (to.path == "/assignStudy" && from.path == "/special-learn") {
        vm.isLean = 0;
        sessionStorage.setItem("isLean", 0);
        sessionStorage.setItem("isSpecial", 1);
        vm.isSpecial = 1;
        vm.getAllUser();
      } else {
        vm.isSpecial = Number(sessionStorage.getItem("isSpecial"));
        vm.isLean = Number(sessionStorage.getItem("isLean"));
      }
    });
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
    },
    optionChooseValueChange() {},
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage4 - 1) * this.curSize;
      this.end = this.start + this.curSize;
      this.getDate();
    },
    handleCurrentChange(e) {
      this.currentPage4 = e;
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
      this.getDate();
    },
    getAllUser() {
      get("/api/User")
        .then((res) => {
          if (res.code == 200) {
            this.option6 = res.data;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$message.error("人员获取失败");
        });
    },
    // 列表查询
    getDate() {
      let queryParams = {
        UserId: JSON.parse(sessionStorage.getItem("userInfo")).id,
        Status: this.optionChoosevalue,
        CompleteDate: this.date1,
        ReleaseYear: this.dateValue,
        TaskType: this.typeValue,
      };
      get("/api/AssignStudy/GetStudyTestByAssign", queryParams).then((res) => {
        this.tableData1 = res.data;
        if (this.isSpecial == 0) {
          this.tableData1 = this.tableData1.filter(
            (item) => item.isTest == 0 || item.isTest == 1 || item.isTest == 4
          );
        } else if (this.isLean == 0) {
          this.tableData1 = this.tableData1.filter(
            (item) => item.isTest == 2 || item.isTest == 3
          );
        }
        this.myCreateNum = this.tableData1.length;
        get("/api/AssignStudy/GetStudyTestByUser", queryParams)
          .then((res) => {
            this.tableData2 = res.data;
            if (this.isSpecial == 0) {
              this.tableData2 = this.tableData2.filter(
                (item) =>
                  item.isTest == 0 || item.isTest == 1 || item.isTest == 4
              );
            } else if (this.isLean == 0) {
              this.tableData2 = this.tableData2.filter(
                (item) => item.isTest == 2 || item.isTest == 3
              );
            }
            this.aboutMy = this.tableData2.length;
          })
          .then(() => {
            this.tableData1 = this.tableData1.filter((i) => {
              if (i.saveTime) {
                i.saveTime = i.saveTime.split("-")[0];
              }
              return i.saveTime == this.dateValue;
            });
            this.tableData2 = this.tableData2.filter((i) => {
              if (i.saveTime) {
                i.saveTime = i.saveTime.split("-")[0];
              }
              return i.saveTime == this.dateValue;
            });
            this.tableData =
              this.selectValue == 1 ? this.tableData1 : this.tableData2;
            if (this.typeValue) {
              this.typeValue =
                this.$route.query.fromQuery.split("-")[1] == "学习" ? 0 : 1;
            }
            if (this.$route.query.fromQuery) {
              this.changeTable2(
                this.$route.query.fromQuery.split("-")[1] == "学习" ? 0 : 1
              );
            }
          });
      });
      get("/api/SettingTheme/GetThemeMonth").then((res) => {
        this.option5 = res.data;
      });
    },
    deltype() {
      if (!this.AssId) {
        this.$message({
          type: "warning",
          message: "请点击下列表格选择你要删除的数据",
        });
        return;
      }
      let url;
      switch (this.changeWork) {
        case 0:
          url = "/api/AssignStudy";
          break;
        case 3:
          url = "/api/AssignThemeTest";
          break;
        case 2:
          url = "/api/AssignThemeStudy";
          break;
        case 1:
          url = "/api/AssignTest";
          break;
        default:
          break;
      }
      this.$confirm("此操作将永久删除该任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod(url + "?Id=" + this.AssId).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.tableData.forEach((i, index) => {
                if (i.id == this.AssId) {
                  this.tableData.splice(index, 1);
                }
              });
            } else {
              this.$message({
                type: "info",
                message: "失败",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    changeAssignTest() {
      let url;
      switch (this.changeWork) {
        case 0:
          url = "/api/AssignStudy/TransferTask";
          break;
        case 2:
          url = "/api/AssignThemeStudy/TransferTask";
          break;
        case 3:
          url = "/api/AssignThemeTest/TransferTask";
          break;
        case 1:
          url = "/api/AssignTest/TransferTask";
          break;
        default:
          break;
      }
      const userIdList = [];
      this.userId.forEach((i) => {
        userIdList.push(i.value);
      });
      let data = {
        assignUserId: JSON.parse(sessionStorage.getItem("userInfo")).id + "",
        assignUserName: JSON.parse(sessionStorage.getItem("userInfo")).userName,
        remark: "",
        theme: this.curE.theme,
        compId: this.curE.compId,
        compName: this.curE.compName,
        deptId: this.curE.deptId,
        deptName: this.curE.deptName,
        postId: this.curE.postId,
        postName: this.curE.postName,
        studyUserId: userIdList.toString(),
        studyUserName: this.userName.toString(),
        status: 0,
        endTime: this.date,
        saveUserId: JSON.parse(sessionStorage.getItem("userInfo")).id + "",
        saveUserName: JSON.parse(sessionStorage.getItem("userInfo")).userName,
      };
      post(url + "?AssignId=" + this.AssId, data).then((res) => {
        if (res.code == 200) {
          this.userName = [];
          this.$message({
            type: "success",
            message: res.message,
          });
          this.getDate();
          //this.change(this.curIndex)
          this.userId = [];
          this.userName = [];
        } else {
          this.$message({
            type: "info",
            message: "失败",
          });
        }
        this.dialogVisible1 = false;
      });
    },
    handleRow(e) {
      this.userId = [];
      this.curE = e;
      this.AssId = e.id;
      this.changeWork = e.isTest;
      this.tableRowIndex = e.index;
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    More() {
      this.Company11 = "";
      this.deptmentName11 = "";
      this.SearchUser = "";
      this.option4 = [];
      this.dialogVisibleMore = true;
    },
    showChoose() {
      let arr = Array.from(new Set(this.Sruetabel.map(JSON.stringify))).map(
        JSON.parse
      );
      this.Sruetabel = arr;
      this.dialogVisiblechoose = true;
    },
    Search() {
      this.option4.forEach((i, index) => {
        if (i.userName == this.SearchUser) {
          let temp = this.option4[index];
          this.option4.splice(index, 1);
          this.option4.unshift(temp);
        }
      });
    },
    searchDelP() {
      this.Sruetabel.forEach((i, index) => {
        if (i.userName == this.SearchUser) {
          let temp = this.Sruetabel[index];
          this.Sruetabel.splice(index, 1);
          this.Sruetabel.unshift(temp);
          //this.$refs.multipleTable.clearSelection()
        }
      });
    },
    changeData2(e) {
      this.start = 0;
      this.end = 10;
      this.currentPage4 = 1;
      this.optionChoosevalue = "";
      if (this.selectValue == 1) {
        this.tableData = this.tableData2;
        this.num = this.aboutMy;
      } else {
        this.tableData = this.tableData1;
        this.num = this.myCreateNum;
      }
      if (e === null) {
        return;
      }
      this.tableData = this.tableData.filter((i) => {
        if (i.saveTime) {
          i.saveTime = i.saveTime.split("-")[0];
        }
        return i.saveTime == e;
      });
    },
    changeData(e) {
      if (this.dialogVisible || this.dialogVisible1) return;
      this.start = 0;
      this.end = 10;
      this.currentPage4 = 1;
      this.optionChoosevalue = "";
      if (this.selectValue == 1) {
        this.tableData = this.tableData2;
        this.num = this.aboutMy;
      } else {
        this.tableData = this.tableData1;
        this.num = this.myCreateNum;
      }
      if (this.typeValue == 1) {
        this.tableData = this.tableData.filter((i) => {
          return i.isTest == 3 || i.isTest == 1;
        });
      } else {
        this.tableData = this.tableData.filter((i) => {
          return i.isTest == 2 || i.isTest == 0;
        });
      }
      if (e === null) {
        return;
      }
      this.tableData = this.tableData.filter((i) => {
        if (i.endTime) {
          i.endTime = i.endTime.split("T")[0];
        }
        return i.endTime == e;
      });
    },
    change(num) {
      this.curIndex = num;
      if (num == 1) {
        this.tableData = this.tableData2;
        this.num = this.aboutMy;
      } else {
        this.tableData = this.tableData1;
        this.num = this.myCreateNum;
      }
    },
    toclear() {
      if (this.selectValue == 1) {
        this.tableData = this.tableData2;
        this.num = this.aboutMy;
      } else {
        this.tableData = this.tableData1;
        this.num = this.myCreateNum;
      }
    },
    toSure() {
      if (this.Sruetabel.label == 0) return;
      this.userId = [];
      this.userName = [];
      this.Sruetabel.forEach((i) => {
        this.userId.push({ value: i.id, label: i.userName });
        this.userName.push(i.userName);
      });
      this.dialogVisibleMore = false;
      this.dialogVisiblechoose = false;
      this.numPerson =
        this.Sruetabel[0].userName + "等" + this.userId.length + "位人";
      this.Suretabel = [];
    },
    addEvent(e, v) {
      if (e === 4) {
        this.handleRow(v);
        this.dialogVisible1 = true;
        this.getAllUser();
        return;
      }
      this.userId = [];
      this.Company1 = {};
      this.Company11 = {};
      this.deptmentName1 = "";
      this.deptmentName11 = "";
      this.option2 = [];
      this.option22 = [];
      this.Area1.value = "";
      this.numPerson = "";
      this.option3 = [];
      this.option4 = [];
      this.SearchUser = "";
      this.date = "";
      this.type = e;
      this.getAllUser();
      this.dialogVisible = true;
      // this.Area1 = "";
    },
    sureAdd() {
      this.addtabel.forEach((i) => {
        this.Sruetabel.push(i);
      });
      if (this.Sruetabel.length > 0) {
        this.$message({
          type: "success",
          message: "已把选中项添加到选中区",
        });
      } else {
        this.$message({
          type: "warning",
          message: "请选择你要添加的人员",
        });
      }
    },
    changeTable(e) {
      this.selectValue = e;
      this.start = 0;
      this.end = 10;
      this.currentPage4 = 1;
      this.curSize = 10;
      // this.optionChoosevalue = "";
      // this.typeValue = "";
      this.indexE = e;
      if (e == 1) {
        this.tableData = this.tableData1;
        this.num = this.aboutMy;
      } else {
        this.tableData = this.tableData2;
        this.num = this.myCreateNum;
      }
    },
    changeTable2(e) {
      this.start = 0;
      this.end = 10;
      this.currentPage4 = 1;
      this.optionChoosevalue = "";
      this.indexE = e;
      if (this.selectValue == 1) {
        this.tableData = this.tableData2;
        this.num = this.aboutMy;
      } else {
        this.tableData = this.tableData1;
        this.num = this.myCreateNum;
      }
      if (e == 1) {
        this.tableData = this.tableData.filter((i) => {
          return i.isTest == 3 || i.isTest == 1;
        });
      } else {
        this.tableData = this.tableData.filter((i) => {
          return i.isTest == 2 || i.isTest == 0;
        });
      }
    },
    handleSelectionChange(e) {
      this.addtabel = e;
    },
    changeDone(e) {
      this.currentPage4 = 1;
      this.start = 0;
      this.end = 10;
      this.currentPage4 = 1;
      if (this.selectValue == 1) {
        this.tableData = this.tableData2;
        this.num = this.aboutMy;
      } else {
        this.tableData = this.tableData1;
        this.num = this.myCreateNum;
      }
      if (e == 0) {
        this.tableData = this.tableData.filter((i) => {
          return i.status == 0;
        });
      } else {
        this.tableData = this.tableData.filter((i) => {
          return i.status == 2;
        });
      }
      if (this.date1) {
        this.tableData = this.tableData.filter((i) => {
          i.endTime = i.endTime.split("T")[0];
          return i.endTime == this.date1;
        });
      }
      this.currentPage4 = 1;
    },
    toTest(row) {
      this.$router.push({
        path: "/AssignTest",
        query: {
          studyUserId: row.studyUserId,
          studyUserName: row.studyUserName,
          AssId: row.id,
          isTheme: row.isTest,
          compId: row.compId,
          deptId: row.deptId,
          postId: row.postId,
        },
      });
    },
    toLearn(row) {
      if (row.isTest == 2) {
        this.$router.push({
          path: "/AspSubject",
          query: {
            AssId: row.id,
            isTheme: row.isTest,
            theme: row.theme,
          },
        });
      } else {
        this.$router.push({
          path: "/file-learn",
          query: {
            compId: row.compId,
            deptId: row.deptId,
            postId: row.postId,
            AssId: row.id,
            isTheme: row.isTest,
          },
        });
      }
    },
    togetArea(e) {
      // get("/api/Department/GetNoTree?CompId=" + e.value).then((res) => {
      //   this.option2 = res.data;
      //   this.deptmentName1 = {};
      //   this.Area1 = {};
      //   this.userId = [];
      // });
      get("/api/Department/GetNoTree?CompId=" + e.value).then((res) => {
        if (!this.dialogVisibleMore) {
          this.option2 = res.data;
          this.deptmentName1 = {};
          this.Area1 = {};
        } else {
          this.option22 = res.data;
        }
        this.userId = [];
      });
    },
    changeList(e) {
      this.themeList = e.toString();
    },
    togetPost(e) {
      // get(
      //   "/api/Post?CompId=" + this.Company1.value + "&DeptId=" + e.value
      // ).then((res) => {
      //   this.option3 = res.data;
      //   this.Area1 = {};
      //   this.userId = [];
      // });
      // get(
      //   "/api/User/GetByCompByDept?CompId=" +
      //     this.Company1.value +
      //     "&DeptId=" +
      //     e.value
      // ).then((res) => {
      //   this.option4 = res.data;
      //   this.addtabel = this.option4;
      //   this.$refs.multipleTable.clearSelection();
      //   if (this.dialogVisibleMore) {
      //     this.$refs.multipleTable.toggleAllSelection();
      //   }
      // });

      get(
        "/api/Post?CompId=" + this.Company1.value + "&DeptId=" + e.value
      ).then((res) => {
        this.option3 = res.data;
        this.Area1 = {};
        this.userId = [];
      });
    },
    togetPost2(e) {
      get(
        "/api/User/GetByCompByDept?CompId=" +
          this.Company11.value +
          "&DeptId=" +
          e
      ).then((res) => {
        this.option4 = res.data;
        this.addtabel = this.option4;
        this.SearchUser = "";
        // this.$refs.multipleTable.clearSelection()
        if (this.dialogVisibleMore) {
          this.$refs.multipleTable.toggleAllSelection();
        }
      });
    },
    sss(e) {
      this.userName = [];
      e.forEach((i) => {
        this.userName.push(i.label);
      });
    },
    delChooseItem() {
      this.delList.forEach((i) => {
        this.Sruetabel.splice(
          this.Sruetabel.findIndex((item) => item.id === i.id),
          1
        );
      });
    },
    handleSelectionChange1(e) {
      this.delList = e;
    },
    addAssignTest() {
      if (this.userId == []) {
        this.$message({
          type: "info",
          message: "请选择考核人员",
        });
        return;
      } else if (this.date == "") {
        this.$message({
          type: "info",
          message: "请选择时间",
        });
        return;
      }
      let url;
      switch (this.type) {
        case 1:
          url = "/api/AssignStudy";
          break;
        case 2:
          url = "/api/AssignThemeTest";
          break;
        case 3:
          url = "/api/AssignThemeStudy";
          break;
        case 0:
          url = "/api/AssignTest";
          break;
        default:
          break;
      }
      const userIdList = [];
      this.userId.forEach((i) => {
        userIdList.push(i.value);
      });
      var postName = "";
      this.option3.forEach((element) => {
        if (element.id === this.Area1.value) {
          postName = element.postName;
        }
      });
      this.dialogVisible = false;

      const data = {
        assignUserId: JSON.parse(sessionStorage.getItem("userInfo")).id + "",
        assignUserName: JSON.parse(sessionStorage.getItem("userInfo")).userName,
        remark: "",
        studyUserId: userIdList.toString(),
        studyUserName: this.userName.toString(),
        compId: this.Company1.value,
        compName: this.Company1.label,
        deptId: this.deptmentName1.value,
        deptName: this.deptmentName1.label,
        postId: this.Area1.value,
        postName: postName,
        status: 0,
        score: 0,
        endTime: this.date,
        theme: this.themeList,
        saveUserId: JSON.parse(sessionStorage.getItem("userInfo")).id + "",
        saveUserName: JSON.parse(sessionStorage.getItem("userInfo")).userName,
      };

      post(url, data).then((res) => {
        if (res.code == 200) {
          this.userName = [];
          this.$message({
            type: "success",
            message: res.message,
          });
          this.getDate();
          //this.change(this.curIndex)
          this.Company1 = "";
          this.deptmentName1 = "";
          this.Area1.value = "";
          this.userId = [];
          this.userName = [];
          this.addtabel = [];
          this.numPerson = "";
          this.Sruetabel = [];
          this.option4 = [];
          this.date = "";
        } else {
          this.$message({
            type: "info",
            message: "失败",
          });
        }
        this.dialogVisible = false;
      });
    },
  },
  created() {},
  mounted() {
    this.getDate();
  },
};
</script>
<style scoped>
.el-table__body tr.current-row > td {
  background: #2281ee !important;
}
</style>
<style lang="less" scoped>
.class_box {
  box-sizing: border-box !important;
  height: 100%;
  .container {
    height: calc(100% - 60px);
  }
}
/deep/.Search i {
  color: rgb(0, 0, 0);
  line-height: 30px !important;
}
.title {
  width: 100%;
  height: 45px;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
/deep/.el-pagination {
  position: absolute;
  right: 0;
  bottom: 30px;
}
/deep/.el-dialog__body {
  padding-top: 0px;
}
.Top_box {
  width: 100%;
  height: 55px;
  background: #4274c0;
  display: flex;
  align-items: center;
  span {
    width: 64px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin-left: 26px;
  }
}
.add_box_top {
  display: flex;
  align-items: center;
}
/deep/.el-select {
  display: flex;
  align-items: center;
}
.select_box {
  height: 30px;
  position: relative;
}
.bottom_box {
  display: flex;
  width: 100%;
  height: calc(100% - 32px);
  .bottom_box_left {
    width: 336px;
    height: 870px;
    border-right: 3px solid #f1f1f1;
    .title {
      width: 96px;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-top: 30px;
      margin-left: 49px;
      margin-bottom: 53px;
    }
    span {
      width: 35px;
      height: 18px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-left: 49px;
    }
    .test1 {
      margin-left: 49px;
      width: 260px;
      margin-top: 23px;
      border-bottom: 3px solid #e7ecf2;
      .test_top {
        width: 330px;
        height: 57px;
        display: flex;
        align-items: center;
        div {
          width: 36px;
          height: 18px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
      }
      .item {
        width: 260px;
        height: 40px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        margin-left: -11px;
        display: flex;
        align-items: center;
        padding-left: 11px;
        cursor: pointer;
      }
      .item:nth-child(3) {
        margin-bottom: 20px;
      }
    }
  }
  .bottom_box_right {
    width: 100%;
    .top {
      width: 72px;
      height: 18px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-top: 20px;
      margin-left: 24px;
    }
    .mid {
      width: 120%;
      margin-left: 5px;
      padding: 10px 0;
      display: flex;
      align-items: center;
    }
    .el-table {
      ::v-deep .el-table__cell {
        background: none;
      }
    }
  }
}
img {
  width: 30px;
}
.active {
  background: #e8ebf4;
  color: #3888db;
}

/deep/ .el-input__inner {
  border-radius: 0 !important;
}
/deep/.el-form-item {
  margin-bottom: 12px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
/deep/.el-select__tags {
  position: absolute;
  bottom: 0;
}
/deep/.el-input__inner {
  position: relative;
  //height: 30px !important;

  font-size: 12px !important;
  // white-space: nowrap !important;
  line-height: 0 !important;
}
.assign {
  margin-left: 8px;
  height: 56px;
  background: #f2f8ff;
  border: 1px solid #cdd2dc;
  border-radius: 8px;
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding:0 10px;
  img {
    width: 21px;
    height: 21px;
  }
  span {
    margin-top: 4px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #646870;
  }
}
</style>
